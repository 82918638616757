<template>
  <div class="container px-3">
    <header>
      <div v-if="!search" class="d-flex align-items-center justify-content-between py-3">
        <span>Kontak</span>
        <div
          type="button"
          @click="search = true"
        >
          <i class="ri-search-line fs-24 color-neutral-500"></i>
        </div>
      </div>
      <div v-else class="row py-3">
        <div class="col-md-4">
          <div class="input-group rounded-pill">
            <input
              type="text"
              class="form-control bg-transparent"
              placeholder="Cari kontak...."
              name="search"
              v-model="keyword"
              autocomplete="off">
            <span class="input-group-text bg-transparent border-0">
              <div
                class="box-icon"
                type="button"
                @click="
                  keyword = '';
                  search = false
                "
              >
                <i v-if="keyword" class="ri-close-circle-fill fs-14 color-neutral-700"></i>
                <i v-else class="ri-search-line fs-14 color-neutral-500"></i>
              </div>
            </span>
          </div>
        </div>
      </div>
    </header>

    <div
      v-if="loading.subDivisions && (filteredBranchContact.length < 1 || filteredCSContact < 1)">
      Loading...
    </div>
    <div v-else-if="filteredBranchContact.length || filteredCSContact.length">
      <section>
        <p class="mb-2">
          <strong class="fw-bold fs-14 lh-24 color-neutral-900">Whatsapp Call Center</strong>
        </p>

        <div class="list-contact d-flex flex-column">
          <a
            v-for="contact in filteredCSContact"
            :key="contact.kontak"
            class="contact d-flex align-items-center justify-content-between"
            @click="logEvent('wa_call_center_click', contact)"
            :href="contact.status === 'WA' ? `https://wa.me/${contact.kontak}` : `tel://${contact.kontak}`">
            <div class="d-flex align-items-center gap-3">
              <img
                :src="contact.icon">

              <div class="contact-group d-flex flex-column">
                <span class="contact-name fw-400 fs-14 lh-18 color-neutral-600">{{ contact.nama }}</span>
                <small class="contact-number fw-bold fs-14 lh-24 color-neutral-900">
                  {{ contact.kontak }}
                </small>
              </div>
            </div>
            <div class="icon-wrapper">
              <img
                src="@/assets/icon/chat-green.png"
                alt=""
              >
            </div>
          </a>
        </div>
      </section>

      <section>
        <p class="mb-2">
          <strong class="fw-bold fs-14 lh-24 color-neutral-900">Kontak Cabang Parahita</strong>
        </p>
        <div class="list-contact d-flex flex-column">
          <a
            v-for="cabang in filteredBranchContact"
            :key="cabang.id"
            class="contact d-flex align-items-center justify-content-between"
            @click="logEvent('cabang_call_center_click', cabang)"
            :href="cabang.tlp ? (cabang.status === 'WA' ? `https://wa.me/${cabang.tlp}` : `tel://${cabang.tlp}`) : '#'">
            <div class="d-flex align-items-center gap-3">
              <img :src="cabang.icon">

              <div class="contact-group d-flex flex-column">
                <p class="contact-name fw-700 fs-14 lh-24 color-neutral-600">
                  {{ cabang.nama }}
                </p>
                <p class="contact-address fw-400 fs-12 lh-14 color-neutral-600">
                  {{ cabang.alamat }}
                </p>
                <small class="contact-number fw-700 fs-14 lh-24 color-neutral-900 mb-0">
                  {{ cabang.tlp || '-' }}
                </small>
              </div>
            </div>
            <div class="icon-wrapper">
              <img
                src="@/assets/icon/phone-orange.png"
                alt=""
              >
            </div>
          </a>
        </div>
      </section>
    </div>
    <div
      v-else
      class="not-found d-flex flex-column text-center pb-5 pt-5">
      <div class="image-wrapper">
        <img src="@/assets/icon/empty.png" alt="">
      </div>
      <p class="text-title-active fw-bold fs-18 lh-28">
        Tidak ada hasil ditemukan
      </p>
      <div class="text-body-light d-flex justify-content-center">
        <p class="fw-400 fs-14 lh-24 mb-0">Maaf kontak cabang yang anda cari belum tersedia.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'
import { getContacts, contacts } from '@/core/contact'
import { getSubDivisions, subDivisions, loading } from '@/core/subDivisions'
import { analytics } from '@/core/firebase-messaging'
import { ref } from 'vue'

export default {
  setup () {
    const branches = computed(() => {
      return subDivisions.value.map(_branch => {
        _branch.tlp_raw = _branch.tlp.replace(/(\s|[()])/g, '')
        return _branch
      })
    })

    const search = ref(false)
    const keyword = ref('')

    const filteredCSContact = computed(() => {
      return contacts.value ? contacts.value.filter((data) => {
        return keyword.value.toLowerCase().split(' ').every(v => data.nama.toLowerCase().includes(v))
      }) : []
    })

    const filteredBranchContact = computed(() => {
      return subDivisions.value ? subDivisions.value.filter((data) => {
        return keyword.value.toLowerCase().split(' ').every(v => data.nama.toLowerCase().includes(v))
      }) : []
    })

    const logEvent = analytics.logEvent

    onMounted(() => {
      nextTick(() => {
        title.value = 'Kontak'
      })

      getContacts()
      getSubDivisions()
    })

    return {
      contacts,
      branches,
      logEvent,
      keyword,
      loading,
      filteredCSContact,
      filteredBranchContact,
      search
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  border: 1px solid #E2E8F0;

  .form-control, .input-group-text {
    height: 34px;
  }

  .form-control:focus{
    box-shadow: none;
  }

  .form-control::placeholder {
    color: #A0AEC0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}

.contact {
  padding: 8px 0;
  margin-bottom: 14px;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

  .contact-group {
    .contact-name {
      margin-bottom: 2px;
    }

    .contact-address {
      margin-bottom: 2px;

      @media (max-width: 540px) {
        display: block;
        width: 239px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  img {
    width: 40px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
}

.contact:last-child {
  margin-bottom: 10px;
}

.not-found {
  .image-wrapper {
    margin-bottom: 24px;
  }

  .text-title-active {
    margin-bottom: 4px;
  }

  .text-body-light p {
    width: 192px;
  }
}
</style>

import { ref } from '@vue/reactivity'
import axios from '../plugins/axios'

export const contacts = ref([])

contacts.value = JSON.parse(localStorage.getItem('contacts')) || []

// Get kontaknya langsung running aja
export const getContacts = async () => {
  try {
    const { data } = await axios.get('app/kontak_wa')

    if (data.success) {
      contacts.value = data.data
      localStorage.setItem('contacts', JSON.stringify(data.data))
    }
  } catch (error) {
    console.error('Error dari profile get contact = ', error)
    throw error
  }
}

getContacts()
